import '../scss/dorpsbelangen.scss';

import 'bootstrap'; // Import Bootstrap's JavaScript
import timeago from 'timeago.js'; // Import timeago

// Init timeago
timeago().render(document.querySelectorAll('.timeago'));

// enable - disable the parallax on menu toggle
$('#navbar1').on('hidden.bs.collapse', function () {
  $('body').removeClass('noparallax');
})
$('#navbar1').on('shown.bs.collapse', function () {
  $('body').addClass('noparallax');
})

// // Navbar behaviour on scroll
// $(window).scroll(function() {
//   onScroll();
// });

// function onScroll(){
//   if( $(window).scrollTop() > 0 ) {
//    $(".navbar").addClass("fixed-top navbar-dark bg-black");
//    $(".navbar").removeClass("absolute-top navbar-light bg-white");
//  } else {
//    $(".navbar").addClass("absolute-top navbar-light bg-white");
//    $(".navbar").removeClass("fixed-top navbar-dark bg-black");
//  }
// }

// onScroll();

// Search input behaviour on focus
$('.js-search')
  .on('focus', function(){
    $(this).data('placeholder', $(this).attr('placeholder')); // Store for blur
    $(this).attr('placeholder', $(this).attr('title'));
  })
  .on('blur', function(){
    $(this).attr('placeholder', $(this).data('placeholder'));
  });
